<template>
    <van-tabbar v-model="active"
    active-color="#f3b908"
    inactive-color="#646566"
    z-index="999999"
    :border="false"
    
  >
        <van-tabbar-item icon="home-o" to="main">
            {{ $t(`94`) }}
        </van-tabbar-item>
        <van-tabbar-item icon="pending-payment" to="withdraw">
            {{ $t(`33`) }} 
        </van-tabbar-item>
        <van-tabbar-item icon="friends-o" to="share">
            {{ $t(`96`) }} 
        </van-tabbar-item>
        <van-tabbar-item icon="user-o" to="user">
            {{ $t(`44`) }} 
        </van-tabbar-item> 
    </van-tabbar>
</template>

<script>
export default {
    name: 'FooterLayout',
    data(){
        return {
            active:0
        }
    },
    props:{
        item:{
            default: 0,
            type: Number,
            required: true
        }
    }
    ,
    created() {
        // this.active = this.item
    },
    methods: {
        
    },
    watch:{
        item(val){
            this.active = this.item
        }
    }
    ,
    computed(){
    }
}

</script>

<style type="scss">
.van-tabbar .van-tabbar-item {
background-color:#181e25!important;
}
:root{
    --van-tabbar-background:none!important;
}
</style>