module.exports = 
{
    STATUS: {
        1: "確認中",
        2: "成功",
        3: "失敗"
    },
    1: "ウォレットの接続",
    2: "バウチャーを受け取る",
    3: "誓約なし",
    4: "ノードに参加してマイニングを開始する",
    5: "100万",
    6: "受け取る",
    7: "プールデータ",
    8: "総出力",
    9: "有効なノード",
    10: "参加者",
    11: "ユーザー収入",
    12: "マイニング",
    13: "流動性マイニング収入",
    14: "ユーザー出力",
    15: "住所",
    16: "数量",
    17: "ヘルプセンター",
    18: "お役に立てば幸いです",
    19: "どうすれば参加できますか？",
    20: "非破壊、無担保の液体マイニングに参加するには、バウチャーを受け取るために{coin}マイナーの料金を支払い、成功後に自動的にマイニング許可を開く必要があります。",
    21: "どうすればお金を引き出すことができますか？",
    22: "契約の有効期限が切れると、プラットフォームは自動的に収益をUSDTに変換し、引き出しを開始できます。USDTの引き出しは、ノードに追加したウォレットアドレスに自動的に送信され、他のアドレスはサポートされていません。 ",
    23: "収入の計算方法は？",
    24: "正常に参加すると、スマートコントラクトは毎{hours}時にノードを介して住所の計算を開始し、収入の計算を開始します。",
    25: "パートナー",
    26: "私たちのビジネスパートナー",
    27: "マイアカウント",
    28: "累積所得",
    29: "ウォレットバランス",
    30: "引き出し可能",
    31: "交換",
    32: "すべて",
    33: "撤回",
    34: "すべて抽出",
    35: "確認",
    36: "時間",
    37: "数量",
    38: "ステータス",
    39: "データなし",
    40: "説明",
    41: "注:誓約なしのマイニングではUSDTを誓約する必要はありませんが、鉱夫の料金を支払う必要があります。ウォレットに鉱夫の料金として十分な{coin}があることを確認してください。",
    42: "マイニングを開始",
    43: "マイニングプール",
    44: "アカウント",
    45: "ウォレットに接続できません",
    46: "最初にウォレットに接続してください",
    47: "操作は成功しました。",
    48: "操作は完了していません。",
    49: "正しい内容を入力してください！",
    50: "交換成功",
    51: "撤退は成功しました",
    52: "記録",
    53: "ウォレットの接続に失敗しました。宝くじを請求できません",
    54: "次のようにしてください:",
    55: "以下のリンクをコピーして{coin}ウォレットブラウザで開きます",
    56: "2。または{coin}ウォレット（Coinbase Wallet、MetaMaskなど）を使用して以下のQRコードをスキャンします",
    57: "コピー",
    58: "コピー成功",
    59: "コピーに失敗しました。手動でコピーしてください",
    60: "口座残高",
    61: "推定収益",
    62: "契約期間",
    63: "完了",
    64: "待っている",
    65: "拒否",
    66: "天国",
    67: "不十分な現金引き出し",
    68: "正常に受信されました",
    69: "契約満了時間",
    70: "マイニングプールテーブル",
    71: "今日の収益",
    72: "誓約",
    73: "開始",
    74: "作成された時間",
    75: "完了時間",
    76: "名前",
    77: "ステータス",
    78: "利益",
    79: "ソース",
    80: "ソース招待コードを入力してください",
    81: "保存",
    82: "リファラーを設定すると、リファラーはマイニングプールから追加の報酬を受け取ります",
    83: "マイシェアリンク",
    84: "コピー",
    85: "招待リンクを送信すると、友達があなたのリンクを介してノードに参加し、寛大なトークン報酬を獲得できます",
    86: "コピー成功",
    87: "コピーに失敗しました。手動でコピーしてください",
    88: "正常に送信されました",
    89: "無効なアドレス",
    90: "オンラインカスタマーサービス",
    91: "期間",
    92: "デイリーイールド",
    93: "制限",
    94: "ホーム",
    95: "撤回",
    96: "共有",
    97: "交換可能",
    98: "から",
    99: "到着",
    100: "価格",
    101: "利用可能",
    102: "利回り",
    103: "合計",
    104: "フリーズ",
    105: "規制当局",
    106: "安全な監査報告書があります",
    107: "分割",
    108: "残高が{balanceLimit} USDTに達すると、マイニングプールから{ボーナス} {コイン}の報酬を受け取ることができます",
    109: "残り時間",
    110: "受け取る",
    111: "正常に受信されました",
    112: "マイニング",
    113: "送金金額",
    114: "到着量",
    115: "転送ハッシュ",
    116: "引き出し額",
    117: "数量",
    118: "これ以上",
    119: "リクエストに失敗しました。クリックしてリロードしてください",
    120: "読み込み中...",
    121: "プルダウンして更新...",
    122: "リリースして更新...",
    123: "私の優れたID",
    124: "手数料",
    125: "総出力",
    126: "交換可能",
    127: "{coin}マイナー料金が不十分です",
    128: "ノードに参加しました",
    129: "思い出させる",
    130: "お財布に足を運んでください",
    131: "現在、分散型Webサイトにアクセスしています。ウォレット・アプリケーションで実行してください",
    132: "チーム",
    133: "総収入",
    134: "今日の収入",
    135: "チームサイズ",
    136: "直接プロモーター",
    137: "すべて",
    138: "レベル1",
    139: "レベル2",
    140: "レベル3",
    141: "レベル",
    142: "友達を紹介する",
    143: "一緒に暗号を稼ぐ",
    144: "{platName}で最大{rate}コミッションを獲得",
    145: "詳細を表示",
    146: "最小残高",
    147: "手数料率",
    148: "1。リンクを取得",
    149: "2。友達を招待する",
    150: "3。暗号を獲得する",
    151: "私のレベル",
    152: "日 ",
    153: "時 ",
    154: "分 ",
    155: "秒 ",
    156: "マイニングプールに参加して非破壊マイニングを開始するためのバウチャーを受け取ります",
    157: "バウチャーを受け取る",
    158: "私のプール",
    159: "スワップ",
    160: "送信",
    161: "共有",
    162: "友達を招待",
    163: "{コイン}の報酬を得るためにマイニングプールに参加するよう友達を招待してください",
    164: "採掘記録",
    165: "時間",
    166: "数量",
    167: "データなし",
    168: "交換可能数量",
    169: "スワップレコード",
    170: "利用可能数量",
    171: "マイニングプールの特典を適用する",
    172: "{chain} -20スマートコントラクト",
    173: "報酬を適用する",
    174: "プールの流動性を追加する",
    175: "標準",
    176: "成功を適用する",
    177: "流動性",
    178: "期間利益",
    179: "期間",
    180: "報酬",
    181: "USDT残高",
    182: "活動記録",
    183: "報酬額",
    184: "リワードコイン",
    185: "ステータス",
    186: "利用可能時間",
    187: "受け取った",
    188: "活動",
    swapToUsdt: "掘った{coin}ドルをUSDTに両替",
    boostRevenue: "質押の収益",
    pledgeBalance: "質押の総額",
    boostYield: "利回り",
    levelIndex: " レベル{level} ",
    inviteStep2: "3段階の報酬は、 ",
    invitationReward: "招待報酬",
    inviteRewards: "{level}. {level}レベルの部下の報酬の{rate}％を獲得できます。",
    inviteRewardsAddition: "PS: あなたの部下を含むすべてのアクティブな顧客もこの待遇を受けることができるため、いいえ。",
    ERC: {
        coinType: "ETH"
    },
    TRX: {
        coinType: "TRX"
    },
    BSC: {
        coinType: "BNB"
    },
    undefined: {
        coinType: "ガス料金"
    },
    LiquidReward: "流動性収益率",
    StakingReward: "質権回収率",
    PerHours: "{hours}時間当たり",
    withdrawToWallet: "USDTを財布に引き出します"
}