import { createApp } from 'vue'
const VueRouter = require('vue-router')
import App from '../App.vue'
const Vue = createApp(App)
import FooterLayout from '../components/FooterLayout.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'FooterLayout',
        component: FooterLayout,
        redirect: '/main',
    }
    ,
    {
        path: '/main',  
        name: 'main',
        component: () => import('@/views/main/Main.vue'),
        meta: { index: 0, name: '首页' }
    },
    {
        path: '/withdraw',  
        name: 'withdraw',
        component: () => import('@/views/withdraw/withdraw.vue'),
        meta: { index: 0, name: '取款款' }
    },
    {
        path: '/share',  
        name: 'share',
        component: () => import('@/views/share/share.vue'),
        meta: { index: 0, name: '分享' }
    },
    {
        path: '/user',  
        name: 'user',
        component: () => import('@/views/user/user.vue'),
        meta: { index: 0, name: '分享' }
    }
    
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes
})

// 解决路由重复点击报错问题
const originalPush = VueRouter.push

// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }


export default router
