
module.exports = 
{
    STATUS: {
        1: "Confirmation",
        2: "Succès",
        3: "Échec"
    },
    1: "Connectez le portefeuille",
    2: "Réception des bons",
    3: "Aucune garantie.",
    4: "Joindre un noeud et commencer à creuser",
    5: "Un million.",
    6: "Réception",
    7: "Pool de données",
    8: "Production totale",
    9: "Noeud valide",
    10: "Participants",
    11: "Recettes des utilisateurs",
    12: "Exploitation minière",
    13: "Liquidité des revenus miniers",
    14: "Détails de sortie",
    15: "Adresse",
    16: "Quantité",
    17: "Centre d'assistance",
    18: "J'espère que ça t'aidera.",
    19: "Comment dois - je me joindre à vous?",
    20: "Pour participer à une exploitation minière non destructive et non classifiée de liquidité, vous devrez payer les mineurs ETH pour recevoir les certificats et ouvrir automatiquement les permissions d'exploitation minière après le succès.",
    21: "Comment retirer les fonds?",
    22: "À l'expiration du contrat, la plateforme convertit automatiquement vos revenus en USD et vous pouvez commencer à retirer de l'argent. Usdt sera automatiquement envoyé à l'adresse portefeuille que vous avez ajoutée au noeud. Aucune autre adresse n'est prise en charge.",
    23: "Comment calculer mon revenu?",
    24: "Une fois que vous vous êtes joint avec succès toutes les heures, le contrat intelligent commencera à calculer votre adresse à travers le noeud et commencera à calculer vos revenus.",
    25: "Partenaires",
    26: "Nos partenaires commerciaux",
    27: "Mon compte",
    28: "Avantages cumulatifs",
    29: "Solde du portefeuille",
    30: "Retrait",
    31: "Echange",
    32: "Tous",
    33: "Repliez - vous!",
    34: "Max.",
    35: "Confirmation",
    36: "Temps",
    37: "Quantité",
    38: "Statut",
    39: "Aucune donnée disponible",
    40: "Description",
    41: "Notes",
    42: "Début de l'exploitation minière",
    43: "Bassin minier",
    44: "Comptes",
    45: "Impossible de se connecter au portefeuille",
    46: "Veuillez d'abord vous connecter au portefeuille",
    47: "L'opération a été couronnée de succès.",
    48: "L'opération n'est pas terminée.",
    49: "Veuillez saisir le contenu correct!",
    50: "Échange réussi",
    51: "Sortie réussie",
    53: "La connexion au portefeuille a échoué, impossible de recevoir les justificatifs d'identité",
    54: "Suivez ces instructions",
    55: "Vous pouvez ouvrir ce lien dans le navigateur de portefeuille",
    56: "2. Ou numériser le Code QR ci - dessous en utilisant un portefeuille (coinbase Wallet metamask, etc.)",
    57: "Copier",
    58: "Copie réussie",
    59: "La copie a échoué, veuillez la copier manuellement",
    60: "Solde du compte",
    61: "Montant estimatif des recettes",
    62: "Durée du contrat",
    63: "Complet",
    64: "En attente",
    65: "Rejeté",
    66: "Oh, mon Dieu.",
    67: "Retrait insuffisant",
    68: "Reçu avec succès",
    69: "Date d'expiration du contrat",
    70: "Table de billard",
    71: "Gains d'aujourd'hui",
    72: "Garantie",
    73: "On y va.",
    74: "Temps de création",
    75: "Date d'achèvement",
    76: "Nom",
    77: "Statut",
    78: "Bénéfices",
    79: "Source:",
    80: "Veuillez saisir le Code d'invitation source",
    81: "Sauver",
    82: "Un groupe de références recevra un bonus supplémentaire du pool minier",
    83: "Mes liens recommandés",
    84: "Copier",
    85: "Envoyez votre lien recommandé. Vos amis se joindront à ce noeud par votre lien et vous recevrez de riches récompenses de jetons",
    86: "Copie réussie",
    87: "La copie a échoué, veuillez la copier manuellement",
    88: "Soumis avec succès",
    89: "Adresse non valable",
    90: "Service à la clientèle en ligne",
    91: "Période",
    92: "Rendement quotidien",
    93: "Restrictions",
    94: "La maison",
    95: "Retrait",
    96: "Communication",
    97: "Interchangeable",
    98: "Depuis...",
    99: "À",
    100: "Prix",
    101: "Disponible",
    102: "Production",
    103: "Tous",
    104: "Gel",
    105: "Organismes de réglementation",
    106: "Nous avons un rapport de vérification de la sécurité",
    107: "Division",
    108: "Lorsque le solde atteint {balanceLimit} usdt, vous pouvez obtenir {bonus} pièces {coin} du pool minier",
    109: "Il reste du temps.",
    110: "Réception",
    111: "Reçu avec succès",
    112: "Exploitation minière",
    113: "Montant du transfert",
    114: "Montant reçu",
    115: "Hachage de transfert",
    116: "Nombre",
    117: "Quantité",
    118: "Plus maintenant.",
    119: "La demande a échoué, cliquez sur recharger",
    120: "Chargement...",
    121: "Faites défiler vers le bas pour rafraîchir...",
    122: "Publier pour rafraîchir...",
    123: "Ma carte d'identité supérieure",
    124: "Frais de manutention",
    125: "Production totale",
    126: "Interchangeable",
    127: "Vous n'avez pas assez d'argent pour les mineurs",
    128: "Vous avez rejoint le noeud",
    129: "Rappel",
    130: "Veuillez visiter le portefeuille",
    131: "Vous visitez actuellement un site Web décentralisé.",
    132: "Équipe",
    133: "Total des recettes",
    134: "Revenus d'aujourd'hui",
    135: "Taille de l'équipe",
    136: "Initiateur direct",
    137: "Tous",
    138: "Niveau 1",
    139: "Niveau 2",
    140: "Niveau 3",
    141: "Nombre",
    142: "Présentez vos amis.",
    143: "Gagnez de la crypto - monnaie ensemble.",
    144: "Gagner une Commission sur [platname]",
    145: "Voir les détails",
    146: "Solde minimal",
    147: "Taux de la Commission",
    148: "1. Obtenir un lien",
    149: "2. Inviter des amis",
    150: "3. Gagner de la crypto - monnaie",
    151: "Mon niveau",
    152: "Un jour.",
    153: "HR ",
    154: "MIN ",
    155: "Secondes",
    156: "Réception des certificats pour rejoindre le bassin minier et commencer l'exploitation non destructive",
    157: "Réception des bons",
    158: "Ma piscine",
    159: "Echange",
    160: "Par la poste",
    161: "Total",
    162: "Inviter des amis",
    163: "Inviter des amis à se joindre au pool minier pour obtenir des récompenses",
    164: "Registres miniers",
    165: "Temps",
    166: "Quantité",
    167: "Aucune donnée disponible",
    168: "Quantité échangeable",
    169: "Échange d'enregistrements",
    170: "Quantité disponible",
    171: "Demande d'attribution d'un bassin minier",
    172: "- 20 Smart Contract",
    173: "Demande de bourse",
    174: "Augmentation du pool de liquidité",
    175: "Critères",
    176: "Demande acceptée",
    177: "Liquidité des actifs",
    178: "Période de profit",
    179: "Période",
    180: "Récompenses",
    181: "Solde usdt",
    182: "Historique des activités",
    183: "Montant de la Bourse",
    184: "Prix",
    185: "Statut",
    186: "Temps disponible",
    187: "Bien reçu.",
    188: "Activités",
    boostRevenue: "Nantissement du produit",
    pledgeBalance: "Solde du gage",
    boostYield: "Production",
    swapToUsdt: "Remplacer {coin} par usdt",
    levelIndex: "Niveau",
    inviteStep2: "Les récompenses de niveau 3 sont les suivantes:",
    invitationReward: "Invitations et récompenses",
    inviteRewards: "{level}. Vous pouvez obtenir un pourcentage du taux de récompense des subordonnés.",
    ERC: {
        coinType: "ETH"
    },
    TRX: {
        coinType: "TRX"
    },
    BSC: {
        coinType: "BNB"
    },
    undefined: {
        coinType: "Prix du gaz"
    },
    inviteRewardsAddition: "Annexe I",
    coinType: "Prix du gaz",
    LiquidReward: "Taux de rendement courant",
    StakingReward: "Rendement du pari",
    PerHours: "Toutes les heures",
    withdrawToWallet: "Retirer USDT dans votre portefeuille"
}