<template>
	<div id="app">
		
		<router-view  @updateRoute="updateRoute"/>

	</div>
	<FooterLayout :item="item"/>
</template>
  
<script>
import FooterLayout from './components/FooterLayout.vue'

export default {
	name: 'app',
	components:{
		FooterLayout
	},
	data(){
		return {
			item: 0
		}
		
	},
	methods:{
		updateRoute(param){
			this.item = param
		}
	},
	created(){
	}
}
</script>

<style lang='scss'>
body,html{
margin: 0;
padding: 0;
overflow-x:hidden;
}
#app {
font-family: 'Avenir', Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
color: #2c3e50;
// margin-top: 60px;
padding: 0;
margin: 0;
height: 100vh;
width: 100vw;
overflow-x:hidden;
}

</style>