module.exports = {
	STATUS: {
		1: "確認中",
		2: "已成功",
		3: "已失敗"
	},
	1: "連接錢包",
	2: "領取優惠券",
	3: "無需質押",
	4: "加入節點開始挖礦",
	5: "一百萬",
	6: "接收",
	7: "池數據",
	8: "總產量",
	9: "有效節點",
	10: "參與者",
	11: "用戶收入",
	12: "採礦",
	13: "流動性挖礦收益",
	14: "用戶輸出",
	15: "地址",
	16: "數量",
	17: "幫助中心",
	18: "希望對你有幫助",
	19: "我需要如何加入？",
	20: "參與非破壞性、無質押流動性挖礦，需要支付{coin}礦工費領取憑證，成功後自動開啟挖礦權限。",
	21: "我如何提款？",
	22: "合約到期後，平台將自動把您的收益兌換成USDT，您可以發起提現。提現的USDT會自動發送到您添加到節點的錢包地址,不支持其他地址。",
	23: "如何計算收入？",
	24: "當你加入成功後，每{hours}小时點智能合約開始通過節點計算你的地址，開始計算收益。",
	25: "合作夥伴",
	26: "我們的商業夥伴",
	27: "我的賬戶",
	28: "累計收益",
	29: "錢包餘額",
	30: "可提現",
	31: "交換",
	32: "全部",
	33: "提現",
	34: "提取所有",
	35: "確認",
	36: "時間",
	37: "數量",
	38: "狀態",
	39: "無數據",
	40: "說明",
	41: "注意: 質押挖礦無需質押您的USDT，但需要支付礦工費，請確保您的錢包有足夠的{coin}作為礦工費。",
	42: "開始挖礦",
	43: "礦池",
	44: "帳戶",
	45: "無法連接到錢包",
	46: "請先連接錢包",
	47: "操作成功。",
	48: "操作未完成。",
	49: "請輸入正確的內容！",
	50: "交換成功",
	51: "提現成功",
	52: "記錄",
	53: "錢包連接失敗，無法領取獎券",
	54: "請按照以下方式操作: ",
	55: "請使用鏈接到{coin}錢包瀏覽器內打開",
	56: "2. 或者使用{coin}錢包（Coinbase Wallet、MetaMask等）掃描下方二維碼",
	57: "複製",
	58: "複製成功",
	59: "複製失敗，請手動複製",
	60: "賬戶餘額",
	61: "預計收益",
	62: "合約時長",
	63: "完成",
	64: "等待中",
	65: "拒絕",
	66: "天",
	67: "可提現金額不足",
	68: "領取成功",
	69: "合約到期時間",
	70: "礦池表",
	71: "今日收益",
	72: "已質押",
	73: "已開始",
	74: "創建時間",
	75: "完成時間",
	76: "名稱",
	77: "狀態",
	78: "盈利",
	79: "來源",
	80: "請輸入來源邀請碼",
	81: "保存",
	82: "設置推薦人，推薦人會從礦池中獲得額外獎勵",
	83: "我的分享鏈接",
	84: "複製",
	85: "發送您的邀請鏈接，好友通過您的鏈接加入節點，您將獲得豐厚的代幣獎勵",
	86: "複製成功",
	87: "複製失敗，請手動複製",
	88: "提交成功",
	89: "地址無效",
	90: "在線客服",
	91: "週期",
	92: "日收益率",
	93: "限制",
	94: "首頁",
	95: "提現",
	96: "分享",
	97: "可交換",
	98: "從",
	99: "至",
	100: "價格",
	101: "可用",
	102: "收益率",
	103: "總計",
	104: "質押",
	105: "監管機構",
	106: "我們有一份安全的審計報告",
	107: "劃分",
	108: "當餘額達到 {balanceLimit} USDT, 您可以從礦池中獲得 {bonus} {coin} 獎勵",
	109: "剩餘時間",
	110: "領取",
	111: "領取成功",
	112: "挖礦",
	113: "劃轉金額",
	114: "到帳金額",
	115: "轉帳Hash",
	116: "提現金額",
	117: "數量",
	118: "沒有更多了",
	119: "請求失敗，點擊重新加載",
	120: "加載中...",
	121: "下拉即可刷新...",
	122: "釋放即可刷新...",
	123: "我的上級ID",
	124: "手續費",
	125: "總產量",
	126: "可交換",
	127: "你的{coin}礦工費不足",
	128: "您已加入節點",
	129: "提醒",
	130: "請訪問錢包",
	131: "您當前訪問的是去中心化網站,請在錢包應用中運行",
	132: "團隊",
	133: "總收入",
	134: "今日收益",
	135: "團隊規模",
	136: "直屬下級",
	137: "全部",
	138: "1級",
	139: "2級",
	140: "3級",
	141: "等級",
	142: "推薦朋友。",
	143: "一起賺取加密貨幣。",
	144: "在 {platName} 中賺取高達 {rate} 的佣金",
	145: "查看詳情",
	146: "最低餘額",
	147: "佣金率",
	148: "1. 獲取鏈接",
	149: "2. 邀請朋友",
	150: "3. 賺取加密貨幣",
	151: "我的等級",
	152: "天 ",
	153: "時 ",
	154: "分 ",
	155: "秒 ",
	156: "領取入池憑證，開始無損挖礦",
	157: "領券",
	158: "我的礦池",
	159: "交換",
	160: "發送",
	161: "分享",
	162: "邀請朋友",
	163: "邀請好友加入礦池獲得{coin}獎勵",
	164: "挖礦記錄",
	165: "時間",
	166: "數量",
	167: "無數據",
	168: "可交換數量",
	169: "交換記錄",
	170: "可用數量",
	171: "申請礦池獎勵",
	172: "{chain}-20 智能合約",
	173: "申請獎勵",
	174: "增加池流動性",
	175: "標準",
	176: "申請成功",
	177: "流動性",
	178: "期間利潤",
	179: "週期",
	180: "獎勵",
	181: "USDT餘額",
	182: "活動記錄",
	183: "獎勵金額",
	184: "獎勵幣",
	185: "狀態",
	186: "可用時間",
	187: "收到",
	188: "活動",
	swapToUsdt: "將挖到的{coin}幣兌換成USDT",
	boostRevenue: "質押收益",
	pledgeBalance: "質押總額",
	boostYield: "收益率",
	invitationReward: "邀請獎勵",
	inviteStep2: "三層獎勵為 ",
	levelIndex: "第{level}級",
	inviteRewards: "{level}. 您可以享受{level}級邀請人礦池收益的{rate}%作為您的獎勵.",
	inviteRewardsAddition: "註： 包含您邀請的用戶在內的所有有效用戶均享受此待遇，以此類推，不限級數，不限人數",
	ERC: {
		coinType: "ETH"
	},
	TRX: {
		coinType: "TRX"
	},
	BSC: {
        coinType: "BNB"
    },
	undefined: {
		coinType: "燃料費"
	},
	LiquidReward: "流動性回報率",
	StakingReward: "質押回報率",
	PerHours: "每{hours}小時",
	withdrawToWallet: "將USDT提現到您的錢包",
	selectNetwork: "選擇網絡",
	EthereumNetwork: "以太坊",
	TronNetwork: "波場鏈",
	BSCNetwork: "BNB智能鏈",
	Cancel: "取消",
	Processing: "處理中"
}