module.exports = {
    STATUS: {
        1: "Confirming",
        2: "Success",
        3: "Failed"
    },
    1: "Connect wallet",
    2: "Receive Voucher",
    3: "No pledge",
    4: "Join the node and start mining",
    5: "One Million",
    6: "Receive",
    7: "Pool Data",
    8: "Total Output",
    9: "Valid Nodes",
    10: "Participants",
    11: "User Revenue",
    12: "Mining",
    13: "Liquidity Mining Revenue",
    14: "Output details ",
    15: "Address",
    16: "Quantity",
    17: "Help Center",
    18: "Hope it can help you",
    19: "How do I need to join?",
    20: "To participate in non-destructive, unpledged liquidity mining, you need to pay the ETH miner fee to receive the certificate and automatically open the mining privilege after success.",
    21: "How do I withdraw my funds?",
    22: "After the contract expires, the platform will automatically convert your earnings into USDT and you can initiate a withdrawal. The USDT will be automatically sent to the wallet address you added to the node, no other addresses are supported.",
    23: "How do I calculate my earnings?",
    24: "After you join successfully, every {hours} hours the smart contract will start to calculate your address through the node and start to calculate the income.",
    25: "Partners",
    26: "Our business partners",
    27: "My Account",
    28: "Accumulated earnings",
    29: "Wallet Balance",
    30: "Withdrawable",
    31: "Exchange",
    32: "ALL",
    33: "Withdraw",
    34: "MAX",
    35: "Confirm",
    36: "Time",
    37: "Quantity",
    38: "Status",
    39: "No data",
    40: "Description",
    41: "Note: Pledge-free mining does not require pledging your USDT, but does require paying a miner's fee, please make sure you have enough {coin} in your wallet for the miner's fee.",
    42: "Start mining",
    43: "Mining Pool",
    44: "Account",
    45: "Unable to connect to wallet",
    46: "Please connect to wallet first",
    47: "Operation was successful.",
    48: "Operation not completed.",
    49: "Please enter the correct content!",
    50: "Exchange successful",
    51: "Successful withdrawal",
    52: "Record",
    53: "The connection to the wallet failed and the voucher cannot be received",
    54: "Please follow the instructions below:",
    55: "You can open this link in the {coin} Wallet browser",
    56: "2. Or use an wallet (Coinbase Wallet, MetaMask, etc.) to scan the QR code below",
    57: "Copy",
    58: "Copy successful",
    59: "Failed to copy, please copy manually",
    60: "Account Balance",
    61: "Estimated earnings",
    62: "Contract Duration",
    63: "Completed",
    64: "Pending",
    65: "Rejected",
    66: "Days",
    67: "Insufficient withdrawal amount",
    68: "Received successfully",
    69: "Contract Expiration Time",
    70: "Pool Table",
    71: "Today's earnings",
    72: "Pledged",
    73: "Started",
    74: "Creation Time",
    75: "Completion Time",
    76: "Name",
    77: "Status",
    78: "Profit",
    79: "Source",
    80: "Please enter source invitation code",
    81: "Save",
    82: "Set referrer, referrer will get extra bonus from mining pool",
    83: "My referral link",
    84: "Copy",
    85: "Send your referral link, your friends will join the node through your link and you will get a great token reward",
    86: "Copy successful",
    87: "Failed to copy, please copy manually",
    88: "Submitted successfully",
    89: "Address is invalid",
    90: "Online Customer Service",
    91: "Period",
    92: "Daily Rate of Return",
    93: "Restrictions",
    94: "Home",
    95: "Withdrawal",
    96: "Referral",
    97: "Exchangeable",
    98: "From",
    99: "To",
    100: "Price",
    101: "Available",
    102: "Yield",
    103: "Total",
    104: "Freeze",
    105: "Regulator",
    106: "We have a secure audit report",
    107: "Divisions",
    108: "When the balance reaches {balanceLimit} USDT, you can earn {bonus} {coin} rewards from the mining pool",
    109: "Time left",
    110: "Receive ",
    111: "Received successfully",
    112: "Mining",
    113: "Transfer Amount",
    114: "Received Amount",
    115: "Transfer Hash",
    116: "Amount",
    117: "Quantity",
    118: "No more",
    119: "Request failed, click Reload",
    120: "Loading...",
    121: "Scroll down to refresh...",
    122: "Release to refresh...",
    123: "My superior ID",
    124: "Handling Fee",
    125: "Total output",
    126: "Exchangeable",
    127: "Your {coin} miner fee is insufficient",
    128: "You have joined the node",
    129: "Remind",
    130: " Please visit in the wallet ",
    131: " You are currently visiting a decentralized website, please run it in the wallet application ",
    132: "Team",
    133: "Total revenue",
    134: "Today's revenue",
    135: "Team size",
    136: "Direct promoters",
    137: "ALL",
    138: "Level 1",
    139: "Level 2",
    140: "Level 3",
    141: "Level",
    142: "Refer Friends.",
    143: "Earn Crypto Together.",
    144: "Earn up to {rate} commission in {platName}",
    145: "View details",
    146: "Minimum Balance",
    147: "Commission Rate",
    148: "1. Get Link",
    149: "2. Invite Friends",
    150: "3. Earn Crypto",
    151: "My Level",
    152: "DAY ",
    153: "HR ",
    154: "MIN ",
    155: "SEC ",
    156: " Receive voucher to join the mining pool and start non-destructive mining ",
    157: "Receive voucher",
    158: "My Pool",
    159: "Swap",
    160: "Send",
    161: "Share",
    162: "Invite friends",
    163: "Invite friends to join the mining pool to get {coin} rewards",
    164: "Mining record",
    165: "Time",
    166: "Quantity",
    167: "No Data",
    168: " Exchangeable quantity ",
    169: "Swap record",
    170: " Available quantity ",
    171: "Apply mining pool rewards",
    172: "{chain}-20 smart contract",
    173: "Apply rewards",
    174: "Add pool liquidity",
    175: "Standard",
    176: "Apply success",
    177: " Liquidity ",
    178: " Period profit ",
    179: " Period ",
    180: " Reward ",
    181: " USDT Balance ",
    182: " Activity records ",
    183: " Reward amount ",
    184: " Reward coin ",
    185: " Status ",
    186: " Available time ",
    187: "Received",
    188: "Activity",
    boostRevenue: "Pledge earnings",
    pledgeBalance: "Pledge Balance",
    boostYield: "Yield",
    swapToUsdt: "Swap {coin} To USDT",
    levelIndex: " Level{level} ",
    inviteStep2: "The three-tier reward is ",
    invitationReward: "Invitation Reward",
    inviteRewards: "{level}. You can obtain {rate}% of your {level} level subordinate's rewards.",
    inviteRewardsAddition: "PS: Every active customer include your subordinate's also can obtain this treatment and as so no.",
    ERC: {
        coinType: "ETH"
    },
    TRX: {
        coinType: "TRX"
    },
    BSC: {
        coinType: "BNB"
    },
    undefined: {
        coinType: "Gas Fee"
    },
    LiquidReward: "Liquid Reward Ratio",
    StakingReward: "Staking Reward Ratio",
    PerHours: "Per {hours} Hours",
    withdrawToWallet: "withdraw USDT to you wallet",
    selectNetwork: "Select Network",
    EthereumNetwork: "Ethereum Network",
    TronNetwork: "Tron Network",
    BSCNetwork: "BNB Smart Chain",
    Cancel: "Cancel",
    Processing: "Processing"
}